
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import store from "@/store";
import router from "@/router";

interface Form {
  email: string;
  password: string;
}

const baseForm: Form = {
  email: "",
  password: "",
};

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const form = ref<Form>(baseForm);
    const loading = ref<boolean>(false);
    const versao = ref<string>("2.8.18");
    const isPwd = ref<boolean>(true);

    const submit = (): void => {
      if (form.value.email && form.value.password) {
        loading.value = true;
        let email = form.value.email;
        let password = form.value.password;
        store
          .dispatch("login", { email, password })
          .then(() => router.push("/escolha-divisao"))
          .catch((err) => {
            showNotify({
              type: "negative",
              message: err,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      if (store.getters.isLoggedIn) {
        router.push("/");
      }
    });

    return {
      requiredField,
      form,
      loading,
      showNotify,
      submit,
      versao,
      isPwd,
    };
  },
});
